import { render, staticRenderFns } from "./CardCommentList.vue?vue&type=template&id=5039364a"
import script from "./CardCommentList.vue?vue&type=script&lang=js"
export * from "./CardCommentList.vue?vue&type=script&lang=js"
import style0 from "./CardCommentList.vue?vue&type=style&index=0&id=5039364a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CAvatar: require('@chakra-ui/vue').CAvatar, CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid})
