var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoading || _vm.isLoadingComment)?_c('Loading',{attrs:{"active":_vm.isLoading || _vm.isLoadingComment}}):(
    (_vm.currentClubPost !== 0 && _vm.currentClub.is_private !== true) ||
    (_vm.currentClubPost !== 0 &&
      _vm.currentClub.is_private === true &&
      _vm.currentClub.is_joined !== 0)
  )?_c('CBox',{attrs:{"d":"flex","justifyContent":"space-between","flexWrap":"wrap"}},[_c('CBox',{attrs:{"borderRadius":"10px","mt":"5","w":['100%', '100%', '74%', '74%'],"bg":"white","shadow":"sm","rounded":"md","py":"24px","px":['10px', '24px', '24px', '24px'],"position":"relative"}},[_c('CText',{attrs:{"fontStyle":"extrabold","fontSize":"3xl","fontWeight":"semibold","mb":"16px"}},[_vm._v("Detail Post")]),_c('CBox',[_c('CBox',{attrs:{"p":"0","py":"8px","mt":"-8px"}},[_c('CBox',{attrs:{"alignItems":"center","mb":"8px","shadow":"sm","rounded":"md","p":"16px"}},[_c('CBox',{attrs:{"d":"flex","justifyContent":"space-between"}},[_c('CBox',{attrs:{"d":"flex","alignItems":"center","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)","pb":"8px"}},[_c('CAvatar',{attrs:{"w":"35px","h":"35px","mr":"12px","name":_vm.currentClubPost.user.emp_full_name.split(' ')[0],"_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: _vm.currentClubPost.emp_id,
                    },
                  })}}}),_c('CBox',[_c('CText',{attrs:{"fontSize":"14px","fontWeight":"600","cursor":"pointer","_hover":{ opacity: '0.8' }},nativeOn:{"click":function($event){return _vm.$router.push({
                      name: 'Profile',
                      params: {
                        emp_id: _vm.currentClubPost.emp_id,
                      },
                    })}}},[_vm._v(_vm._s(_vm.currentClubPost.emp_id === _vm.currentUser.emp_id ? "You" : _vm.currentClubPost.user.emp_full_name))]),_c('CBox',{attrs:{"d":"flex","alignItems":"center"}},[_c('CText',{attrs:{"fontSize":"11px","mr":"4px"}},[_vm._v(_vm._s(_vm.formatDate( _vm.currentClubPost.update_at ? _vm.currentClubPost.update_at : _vm.currentClubPost.created_at )))]),_c('CText',{attrs:{"fontSize":"10","fontWeight":"400"}},[_vm._v(_vm._s(_vm.currentClubPost.is_edited ? "Edited" : ""))])],1)],1)],1),(_vm.currentUser)?_c('CBox',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],attrs:{"position":"relative"}},[_c('CBox',{attrs:{"w":"fit-content","h":"fit-content","bg":"transparent","_hover":{ bg: 'transparent' },"_focus":{ outline: 0, boxShadow: 'none', border: 0 },"_active":{ bg: 'transparent' },"cursor":"pointer"},on:{"click":function($event){return _vm.toggleDropdown(!_vm.dropdownStatus)}}},[_c('ButtonIcon',{attrs:{"icon":"ic-menu-solid.svg"}})],1),_c('CBox',{directives:[{name:"show",rawName:"v-show",value:(_vm.dropdownStatus),expression:"dropdownStatus"}],attrs:{"position":"absolute","top":"20px","left":"50%","mr":"4px","transform":"translateX(-50%)","backgroundColor":"white","boxShadow":"0px 4px 8px rgba(0, 0, 0, 0.1)","py":"8px","borderRadius":"4px","w":"120px"}},[(_vm.currentClubPost.user.emp_id === _vm.currentUser.emp_id)?[_c('CBox',{attrs:{"as":"a","href":_vm.currentUrl + '/edit',"d":"block","minH":"32px","py":"4px","px":"16px","w":"full","cursor":"pointer","_hover":{
                      opacity: '0.8',
                      backgroundColor: 'var(--colors-gray-100)',
                      outline: '2px solid transparent',
                    }}},[_vm._v("Edit")]),_c('CBox',{attrs:{"min-height":"32px","py":"4px","px":"16px","w":"full","cursor":"pointer","_hover":{
                      opacity: '0.8',
                      backgroundColor: 'var(--colors-gray-100)',
                      outline: '2px solid transparent',
                    }},nativeOn:{"click":function($event){return _vm.deleteData(_vm.currentClubPost.id, _vm.currentClubPost.title)}}},[_vm._v("Delete")])]:[_c('CBox',{attrs:{"minH":"32px","px":"16px","w":"full","cursor":"pointer","_hover":{
                      opacity: '0.8',
                      backgroundColor: 'var(--colors-gray-100)',
                      outline: '2px solid transparent',
                    },"py":"4px"}},[_vm._v("Report")])]],2)],1):_vm._e()],1),_c('CBox',{attrs:{"px":['10px', '40px', '40px', '40px'],"py":"8px"}},[_c('CText',{attrs:{"fontWeight":"600","fontSize":"18px"}},[_vm._v(_vm._s(_vm.currentClubPost.title))]),_c('CText',{attrs:{"py":"2px","mb":"8px","fontSize":"14px","fontWeight":"400","lineHeight":"26px","white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.formattedCaption)}}),(
                _vm.currentClubPost.photos && _vm.currentClubPost.photos.length > 0
              )?_c('CBox',{attrs:{"white-space":"nowrap","position":"relative","w":['200px', '500px', '500px', '500px'],"h":['200px', '500px', '500px', '500px'],"mx":"auto"}},[_vm._l((_vm.currentClubPost.photos),function(photo,i){return _c('CBox',{key:i,attrs:{"position":"absolute","top":"0","left":"0","w":"100%","h":"100%","transition":"transform 0.3s ease-in-out","hidden":(!_vm.activeImages[_vm.currentClubPost.id] === false &&
                    _vm.activeImages[_vm.currentClubPost.id] !== i) ||
                  (!_vm.activeImages[_vm.currentClubPost.id] === true && i !== 0)}},[_c('CImage',{attrs:{"src":photo,"w":"full","h":"full","object-fit":"cover"}})],1)}),_c('CBox',{attrs:{"position":"absolute","bottom":"8px","left":"50%","transform":"translateX(-50%)","display":"flex"}},_vm._l((_vm.currentClubPost.photos),function(photo,i){return _c('CBox',{key:i,attrs:{"w":"8px","h":"8px","bg":"white","borderRadius":"100%","mx":"2px","opacity":(!_vm.activeImages[_vm.currentClubPost.id] && i === 0) ||
                    _vm.activeImages[_vm.currentClubPost.id] === i
                      ? '1'
                      : '0.5',"cursor":"pointer"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.setActiveImage(_vm.currentClubPost.id, i)}}})}),1),(_vm.currentClubPost.photos.length > 1)?_c('CButton',{attrs:{"position":"absolute","borderRadius":"full","top":"50%","right":"0","transform":"translateY(-50%)","_hover":{ opacity: '1' },"opacity":"0.5","color":"black","mr":"4px","size":"0","w":['24px', '34px', '34px', '34px'],"h":['24px', '34px', '34px', '34px']},on:{"click":function($event){return _vm.nextPhoto(_vm.currentClubPost.id, _vm.currentClubPost.photos.length)}}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])]):_vm._e(),(_vm.currentClubPost.photos.length > 1)?_c('CButton',{attrs:{"position":"absolute","borderRadius":"full","top":"50%","left":"0","transform":"translateY(-50%)","_hover":{ opacity: '1' },"opacity":"0.5","color":"black","ml":"4px","size":"0","w":['24px', '34px', '34px', '34px'],"h":['24px', '34px', '34px', '34px']},on:{"click":function($event){return _vm.prevPhoto(_vm.currentClubPost.id, _vm.currentClubPost.photos.length)}}},[_c('svg',{staticClass:"h-6 w-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})])]):_vm._e()],2):_vm._e()],1),_c('CBox',{attrs:{"d":"flex","justifyContent":"space-between","alignItems":"center","py":"16px","borderBottom":"1px solid var(--Neutral-Light-Gray, #EFF1F5)"}},[(
                _vm.currentClubPost.user_liked &&
                _vm.currentClubPost.user_liked.length === 1
              )?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"mr":"4px","cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: _vm.currentClubPost.user_liked[0].emp_id,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post ")])],1):_vm._e(),(
                _vm.currentClubPost.user_liked &&
                _vm.currentClubPost.user_liked.length === 2
              )?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer","mr":"4px"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: _vm.currentClubPost.user_liked[0].emp_id,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"fontWeight":"400","fontSize":"12px","d":"inline-block","mr":"4px"}},[_vm._v(" and ")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer","mr":"4px"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: _vm.currentClubPost.user_liked[1].emp_id,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[1].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post")])],1):_vm._e(),(
                _vm.currentClubPost.user_liked &&
                _vm.currentClubPost.user_liked.length === 3
              )?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: _vm.currentClubPost.user_liked[0].emp_id,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"fontWeight":"400","fontSize":"12px","d":"inline-block","mr":"4px"}},[_vm._v(" , ")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: _vm.currentClubPost.user_liked[1].emp_id,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[1].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400","mr":"4px"}},[_vm._v(" , and")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer","mr":"4px"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: _vm.currentClubPost.user_liked[2].emp_id,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[2].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post")])],1):_vm._e(),(
                _vm.currentClubPost.user_liked &&
                _vm.currentClubPost.user_liked.length > 3
              )?_c('CBox',[_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: 3434,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[0].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"fontWeight":"400","fontSize":"12px","d":"inline-block","mr":"4px"}},[_vm._v(" , ")]),_c('CText',{attrs:{"d":"inline-block","w":"fit-content","fontSize":"12px","fontWeight":"600","_hover":{ opacity: '0.8' },"cursor":"pointer"},nativeOn:{"click":function($event){return _vm.$router.push({
                    name: 'Profile',
                    params: {
                      emp_id: 3434,
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.currentClubPost.user_liked[1].emp_full_name.split(" ")[0])+" ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400","mr":"4px"}},[_vm._v(" , and ")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"600","mr":"4px"}},[_vm._v(" "+_vm._s(_vm.currentClubPost.total_like - 2)+" Others")]),_c('CText',{attrs:{"d":"inline-block","fontSize":"12px","fontWeight":"400"}},[_vm._v(" liked this post")])],1):_vm._e(),_c('CBox',{attrs:{"ml":"auto","d":"flex","justifyContent":"space-between","alignItems":"center"}},[_c('CBox',{key:_vm.currentClubPost.id,attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center"},nativeOn:{"click":function($event){_vm.postResultLike(_vm.currentClubPost.id);
                  $event.stopPropagation();}}},[_c('CImage',{attrs:{"src":_vm.currentClubPost.is_liked === 1
                      ? require('@/assets/icon/icon-heart-solid.svg')
                      : require('@/assets/icon/icon-heart-regular.svg'),"w":"24px","h":"24px","mr":"16px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mr":"16px","mt":"4px"}},[_vm._v(" "+_vm._s(_vm.currentClubPost.total_like)+" ")])],1),_c('CBox',{attrs:{"cursor":"pointer","_hover":{ opacity: '1' },"d":"flex","flexDirection":"column","alignItems":"center"},nativeOn:{"click":function($event){return _vm.setIsCommentOpen(!_vm.isCommentOpen)}}},[_c('CImage',{attrs:{"src":require('@/assets/icon/icon-comment-regular.svg'),"w":"24px","h":"24px"}}),_c('CText',{attrs:{"fontSize":"10px","color":"#ACACAC","mt":"4px"}},[_vm._v(" "+_vm._s(_vm.currentComments.length)+" ")])],1)],1)],1),_c('CBox',[_c('CommentList',{attrs:{"isOpen":_vm.isCommentOpen,"result_id":_vm.result_id,"title":`Comments`,"comments":_vm.currentComments,"user_suggestions":_vm.currentUserSuggestions,"hashtag_suggestions":_vm.currentHashtagSuggestions,"type":'POST'},on:{"updateData":_vm.updateData}})],1)],1)],1)],1)],1),(!_vm.isLoading && _vm.errorMessage !== 'club not found')?_c('CBox',{attrs:{"w":['100%', '100%', '25%', '25%']}},[_c('SidebarDetailClub')],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }