<template>
  <CBox>
    <CBox pt="2" pb="0">
      <CText
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="18px"
        fontWeight="600"
        color="fontMain"
        my="8px"
        >{{ title }}</CText
      >
    </CBox>
    <CBox overflowY="scroll" maxH="250px" py="0">
      <CBox v-if="comments.length > 0">
        <CardCommentList
          v-for="(comment, index) in comments"
          :key="index"
          :index="index"
          :id="comment.id"
          :result_id="comment.content_id"
          :challenge_slug="currentAppType.slug"
          :sender_id="comment.emp_id.toString()"
          :sender="comment.user.emp_full_name"
          :body="comment.body"
          :likes="comment.likes"
          :isLikedByUser="comment.isLikedByUser"
          :current_user="currentUser"
          :base_url="baseUrl"
          :created_at="comment.created_at"
          :updated_at="comment.updated_at"
        />
      </CBox>
      <CBox v-else py="4" textAlign="center">
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="18px"
          fontWeight="500"
          color="fontMain"
          >No comments found.</CText
        >
      </CBox>
    </CBox>
    <CBox pt="2" justifyContent="flex-start">
      <CBox v-if="currentUser" w="100%" d="flex">
        <CBox v-if="isOpen && selectedComment.id === 0" w="100%" d="flex">
          <Mentionable :keys="['#']" :items="hashtagSuggestions" offset="5">
            <CInput
              v-model="input.text"
              ref="inputCommentRef"
              placeholder="Add a comment here, # to mention hashtag"
              w="100%"
            />
          </Mentionable>
          <ButtonBlue
            :disabled="!input.text"
            label="Post"
            @click.native="postComment"
          />
        </CBox>
        <CBox v-if="selectedComment.id !== 0" w="100%" d="flex">
          <Mentionable :keys="['#']" :items="hashtagSuggestions" offset="5">
            <CInput
              v-model="input.text"
              ref="inputCommentRef"
              placeholder="Add a comment here, # to mention hashtag"
              w="100%"
            />
          </Mentionable>
          <ButtonBlue
            :disabled="!input.text"
            label="Edit"
            @click.native="handleEdit"
          />
        </CBox>
      </CBox>
      <CBox v-else w="100%" d="flex" justifyContent="center">
        <CButton
          bg="bgButtonClub"
          color="white"
          :_hover="{ opacity: '0.8' }"
          size="0"
          py="8px"
          px="16px"
          @click.native="redirectToSignIn"
          >Login to comment</CButton
        >
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { Mentionable } from "vue-mention";

import ButtonBlue from "@/components/button/ButtonBlue.vue";
import CardCommentList from "@/components/card/CardCommentList.vue";

import config from "@/ConfigProvider";
import {
  POST_COMMENT,
  GET_COMMENTS,
  POST_COMMENT_UPDATE,
  RESET_SELECTED_COMMENT,
  SET_TOGGLE_COMMENTS_MODAL,
} from "@/store/comment.module";

export default {
  components: {
    ButtonBlue,
    CardCommentList,
    Mentionable,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    result_id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    comments: {
      type: Array,
      default: () => {
        return [];
      },
    },
    user_suggestions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hashtag_suggestions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: "result",
    },
  },
  data() {
    return {
      isLoading: true,
      baseUrl: config.value("GETHIRED_WEB_URL"),
      hashtagSuggestions: [],
      input: {
        text: "",
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    selectedComment() {
      return this.$store.getters.currentSelectedComment;
    },
  },
  watch: {
    selectedComment(val) {
      this.input.text = val.body;
    },
    hashtag_suggestions(val) {
      this.hashtagSuggestions = this.updateSuggestionsFormat(val);
    },
  },
  mounted() {
    this.hashtagSuggestions = this.updateSuggestionsFormat(
      this.hashtag_suggestions
    );
  },
  methods: {
    redirectToSignIn() {
      window.location.href = this.baseUrl + "/signin";
    },
    updateSuggestionsFormat(rawData) {
      let formattedSuggestions = [];

      if (rawData && rawData.length > 0) {
        rawData.map((data) => {
          formattedSuggestions.push({ value: data.slug, label: data.name });
        });
      }

      return formattedSuggestions;
    },
    async postComment() {
      if (this.currentUser) {
        if (this.selectedComment.id === 0) {
          await this.$store.dispatch(POST_COMMENT, {
            id: this.result_id,
            data: { body: this.input.text, type: this.type },
          });
        } else {
          await this.$store.dispatch(POST_COMMENT_UPDATE, {
            id: this.selectedComment.id,
            data: { body: this.input.text, type: this.type },
          });
        }

        this.resetCommmentInput();
        await this.$store.dispatch(GET_COMMENTS, { id: this.result_id });
        this.$emit("updateData");
      } else {
        this.redirectToSignIn();
      }
    },
    resetCommmentInput() {
      this.$store.commit(RESET_SELECTED_COMMENT);
      this.input.text = "";
    },
    close() {
      this.$store.commit(SET_TOGGLE_COMMENTS_MODAL, false);
      this.$emit("updateData");
    },
    handleEdit() {
      this.postComment();
      this.isEdit = !this.isEdit;
    },
  },
};
</script>

<style>
.mentionable {
  width: 100%;
  margin-right: 12px;
}

.popover {
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #29aafe;
  color: #ffffff;
}
</style>
