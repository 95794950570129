<template>
  <Loading
    v-if="isLoading || isLoadingComment"
    :active="isLoading || isLoadingComment"
  />
  <CBox
    v-else-if="
      (currentClubPost !== 0 && currentClub.is_private !== true) ||
      (currentClubPost !== 0 &&
        currentClub.is_private === true &&
        currentClub.is_joined !== 0)
    "
    d="flex"
    justifyContent="space-between"
    flexWrap="wrap"
  >
    <CBox
      borderRadius="10px"
      mt="5"
      :w="['100%', '100%', '74%', '74%']"
      bg="white"
      shadow="sm"
      rounded="md"
      py="24px"
      :px="['10px', '24px', '24px', '24px']"
      position="relative"
    >
      <CText
        fontStyle="extrabold"
        fontSize="3xl"
        fontWeight="semibold"
        mb="16px"
        >Detail Post</CText
      >
      <CBox>
        <CBox p="0" py="8px" mt="-8px">
          <CBox alignItems="center" mb="8px" shadow="sm" rounded="md" p="16px">
            <CBox d="flex" justifyContent="space-between">
              <CBox
                d="flex"
                alignItems="center"
                borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
                pb="8px"
              >
                <CAvatar
                  w="35px"
                  h="35px"
                  mr="12px"
                  :name="currentClubPost.user.emp_full_name.split(' ')[0]"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: currentClubPost.emp_id,
                      },
                    })
                  "
                ></CAvatar>
                <CBox>
                  <CText
                    fontSize="14px"
                    fontWeight="600"
                    cursor="pointer"
                    :_hover="{ opacity: '0.8' }"
                    @click.native="
                      $router.push({
                        name: 'Profile',
                        params: {
                          emp_id: currentClubPost.emp_id,
                        },
                      })
                    "
                    >{{
                      currentClubPost.emp_id === currentUser.emp_id
                        ? "You"
                        : currentClubPost.user.emp_full_name
                    }}</CText
                  >
                  <CBox d="flex" alignItems="center">
                    <CText fontSize="11px" mr="4px">{{
                      formatDate(
                        currentClubPost.update_at
                          ? currentClubPost.update_at
                          : currentClubPost.created_at
                      )
                    }}</CText>
                    <CText fontSize="10" fontWeight="400">{{
                      currentClubPost.is_edited ? "Edited" : ""
                    }}</CText>
                  </CBox>
                </CBox>
              </CBox>
              <CBox
                v-if="currentUser"
                position="relative"
                v-click-outside="closeDropdown"
              >
                <CBox
                  w="fit-content"
                  h="fit-content"
                  bg="transparent"
                  :_hover="{ bg: 'transparent' }"
                  :_focus="{ outline: 0, boxShadow: 'none', border: 0 }"
                  :_active="{ bg: 'transparent' }"
                  @click="toggleDropdown(!dropdownStatus)"
                  cursor="pointer"
                >
                  <ButtonIcon icon="ic-menu-solid.svg" />
                </CBox>
                <CBox
                  v-show="dropdownStatus"
                  position="absolute"
                  top="20px"
                  left="50%"
                  mr="4px"
                  transform="translateX(-50%)"
                  backgroundColor="white"
                  boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                  py="8px"
                  borderRadius="4px"
                  w="120px"
                >
                  <template
                    v-if="currentClubPost.user.emp_id === currentUser.emp_id"
                  >
                    <CBox
                      as="a"
                      :href="currentUrl + '/edit'"
                      d="block"
                      minH="32px"
                      py="4px"
                      px="16px"
                      w="full"
                      cursor="pointer"
                      :_hover="{
                        opacity: '0.8',
                        backgroundColor: 'var(--colors-gray-100)',
                        outline: '2px solid transparent',
                      }"
                      >Edit</CBox
                    >
                    <CBox
                      @click.native="
                        deleteData(currentClubPost.id, currentClubPost.title)
                      "
                      min-height="32px"
                      py="4px"
                      px="16px"
                      w="full"
                      cursor="pointer"
                      :_hover="{
                        opacity: '0.8',
                        backgroundColor: 'var(--colors-gray-100)',
                        outline: '2px solid transparent',
                      }"
                      >Delete</CBox
                    >
                  </template>
                  <template v-else>
                    <CBox
                      minH="32px"
                      px="16px"
                      w="full"
                      cursor="pointer"
                      :_hover="{
                        opacity: '0.8',
                        backgroundColor: 'var(--colors-gray-100)',
                        outline: '2px solid transparent',
                      }"
                      py="4px"
                      >Report</CBox
                    >
                  </template>
                </CBox>
              </CBox>
            </CBox>
            <CBox :px="['10px', '40px', '40px', '40px']" py="8px">
              <CText fontWeight="600" fontSize="18px">{{
                currentClubPost.title
              }}</CText>
              <CText
                py="2px"
                mb="8px"
                fontSize="14px"
                fontWeight="400"
                lineHeight="26px"
                white-space="pre-line"
                v-html="formattedCaption"
              ></CText>
              <CBox
                v-if="
                  currentClubPost.photos && currentClubPost.photos.length > 0
                "
                white-space="nowrap"
                position="relative"
                :w="['200px', '500px', '500px', '500px']"
                :h="['200px', '500px', '500px', '500px']"
                mx="auto"
              >
                <CBox
                  v-for="(photo, i) in currentClubPost.photos"
                  :key="i"
                  position="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  h="100%"
                  transition="transform 0.3s ease-in-out"
                  :hidden="
                    (!activeImages[currentClubPost.id] === false &&
                      activeImages[currentClubPost.id] !== i) ||
                    (!activeImages[currentClubPost.id] === true && i !== 0)
                  "
                >
                  <CImage :src="photo" w="full" h="full" object-fit="cover" />
                </CBox>
                <CBox
                  position="absolute"
                  bottom="8px"
                  left="50%"
                  transform="translateX(-50%)"
                  display="flex"
                >
                  <CBox
                    v-for="(photo, i) in currentClubPost.photos"
                    :key="i"
                    w="8px"
                    h="8px"
                    bg="white"
                    borderRadius="100%"
                    mx="2px"
                    :opacity="
                      (!activeImages[currentClubPost.id] && i === 0) ||
                      activeImages[currentClubPost.id] === i
                        ? '1'
                        : '0.5'
                    "
                    @click.native.stop="setActiveImage(currentClubPost.id, i)"
                    cursor="pointer"
                  />
                </CBox>
                <CButton
                  v-if="currentClubPost.photos.length > 1"
                  position="absolute"
                  borderRadius="full"
                  top="50%"
                  right="0"
                  transform="translateY(-50%)"
                  :_hover="{ opacity: '1' }"
                  opacity="0.5"
                  color="black"
                  @click="
                    nextPhoto(currentClubPost.id, currentClubPost.photos.length)
                  "
                  mr="4px"
                  size="0"
                  :w="['24px', '34px', '34px', '34px']"
                  :h="['24px', '34px', '34px', '34px']"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </CButton>
                <CButton
                  v-if="currentClubPost.photos.length > 1"
                  position="absolute"
                  borderRadius="full"
                  top="50%"
                  left="0"
                  transform="translateY(-50%)"
                  :_hover="{ opacity: '1' }"
                  opacity="0.5"
                  color="black"
                  @click="
                    prevPhoto(currentClubPost.id, currentClubPost.photos.length)
                  "
                  ml="4px"
                  size="0"
                  :w="['24px', '34px', '34px', '34px']"
                  :h="['24px', '34px', '34px', '34px']"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </CButton>
              </CBox>
            </CBox>
            <CBox
              d="flex"
              justifyContent="space-between"
              alignItems="center"
              py="16px"
              borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
            >
              <CBox
                v-if="
                  currentClubPost.user_liked &&
                  currentClubPost.user_liked.length === 1
                "
              >
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  mr="4px"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: currentClubPost.user_liked[0].emp_id,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[0].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText d="inline-block" fontSize="12px" fontWeight="400">
                  liked this post
                </CText>
              </CBox>
              <CBox
                v-if="
                  currentClubPost.user_liked &&
                  currentClubPost.user_liked.length === 2
                "
              >
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  mr="4px"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: currentClubPost.user_liked[0].emp_id,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[0].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText
                  fontWeight="400"
                  fontSize="12px"
                  d="inline-block"
                  mr="4px"
                >
                  and
                </CText>
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  mr="4px"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: currentClubPost.user_liked[1].emp_id,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[1].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText d="inline-block" fontSize="12px" fontWeight="400">
                  liked this post</CText
                >
              </CBox>
              <CBox
                v-if="
                  currentClubPost.user_liked &&
                  currentClubPost.user_liked.length === 3
                "
              >
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: currentClubPost.user_liked[0].emp_id,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[0].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText
                  fontWeight="400"
                  fontSize="12px"
                  d="inline-block"
                  mr="4px"
                >
                  ,
                </CText>
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: currentClubPost.user_liked[1].emp_id,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[1].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText
                  d="inline-block"
                  fontSize="12px"
                  fontWeight="400"
                  mr="4px"
                >
                  , and</CText
                >
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  mr="4px"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: currentClubPost.user_liked[2].emp_id,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[2].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText d="inline-block" fontSize="12px" fontWeight="400">
                  liked this post</CText
                >
              </CBox>
              <CBox
                v-if="
                  currentClubPost.user_liked &&
                  currentClubPost.user_liked.length > 3
                "
              >
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: 3434,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[0].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText
                  fontWeight="400"
                  fontSize="12px"
                  d="inline-block"
                  mr="4px"
                >
                  ,
                </CText>
                <CText
                  d="inline-block"
                  w="fit-content"
                  fontSize="12px"
                  fontWeight="600"
                  :_hover="{ opacity: '0.8' }"
                  cursor="pointer"
                  @click.native="
                    $router.push({
                      name: 'Profile',
                      params: {
                        emp_id: 3434,
                      },
                    })
                  "
                >
                  {{
                    currentClubPost.user_liked[1].emp_full_name.split(" ")[0]
                  }}
                </CText>
                <CText
                  d="inline-block"
                  fontSize="12px"
                  fontWeight="400"
                  mr="4px"
                >
                  , and
                </CText>
                <CText
                  d="inline-block"
                  fontSize="12px"
                  fontWeight="600"
                  mr="4px"
                >
                  {{ currentClubPost.total_like - 2 }} Others</CText
                >
                <CText d="inline-block" fontSize="12px" fontWeight="400">
                  liked this post</CText
                >
              </CBox>
              <CBox
                ml="auto"
                d="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CBox
                  cursor="pointer"
                  :_hover="{ opacity: '1' }"
                  :key="currentClubPost.id"
                  @click.native="
                    postResultLike(currentClubPost.id);
                    $event.stopPropagation();
                  "
                  d="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <CImage
                    :src="
                      currentClubPost.is_liked === 1
                        ? require('@/assets/icon/icon-heart-solid.svg')
                        : require('@/assets/icon/icon-heart-regular.svg')
                    "
                    w="24px"
                    h="24px"
                    mr="16px"
                  />
                  <CText fontSize="10px" color="#ACACAC" mr="16px" mt="4px">
                    {{ currentClubPost.total_like }}
                  </CText>
                </CBox>
                <CBox
                  cursor="pointer"
                  :_hover="{ opacity: '1' }"
                  @click.native="setIsCommentOpen(!isCommentOpen)"
                  d="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <CImage
                    :src="require('@/assets/icon/icon-comment-regular.svg')"
                    w="24px"
                    h="24px"
                  />
                  <CText fontSize="10px" color="#ACACAC" mt="4px">
                    {{ currentComments.length }}
                  </CText>
                </CBox>
              </CBox>
            </CBox>
            <CBox>
              <CommentList
                :isOpen="isCommentOpen"
                :result_id="result_id"
                :title="`Comments`"
                :comments="currentComments"
                :user_suggestions="currentUserSuggestions"
                :hashtag_suggestions="currentHashtagSuggestions"
                :type="'POST'"
                @updateData="updateData"
              />
            </CBox>
          </CBox>
        </CBox>
      </CBox>
    </CBox>
    <CBox
      v-if="!isLoading && errorMessage !== 'club not found'"
      :w="['100%', '100%', '25%', '25%']"
    >
      <SidebarDetailClub />
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import CommentList from "@/components/list/CommentList.vue";

import { CBox, CText, CImage, CAvatar } from "@chakra-ui/vue";
import ButtonIcon from "@/components/button/ButtonIcon.vue";
import {
  GET_CLUB_POST_DETAIL,
  DELETE_CLUB_POST,
  GET_CLUB_DETAIL,
} from "@/store/club.module";
import { POST_LIKE, REMOVE_LIKE } from "@/store/like.module";
import {
  GET_COMMENTS,
  GET_USER_SUGGESTIONS,
  RESET_SELECTED_COMMENT,
} from "@/store/comment.module";
import SidebarDetailClub from "@/components/sidebar/DetailClub.vue";
import Swal from "sweetalert2";
import config from "@/ConfigProvider.js";
import moment from "moment-timezone";

export default {
  metaInfo() {
    return {
      title: "Club " + "Detail Post",
      titleTemplate: "Devcode - %s",
    };
  },
  name: "DetailClub",
  components: {
    Loading,
    CBox,
    CText,
    CImage,
    CAvatar,
    ButtonIcon,
    CommentList,
    SidebarDetailClub,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: true,
      isLoadingComment: false,
      logo: "",
      urlGethired: config.value("GETHIRED_WEB_URL"),
      defaultTimezone: "Asia/Jakarta",
      mergedParticipants: {},
      activeImages: {},
      titleComment: "",
      result_id: "",
      errorMessage: "",
      isCommentOpen: false,
      dropdownStatus: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentUrl() {
      return this.$route.fullPath;
    },
    currentClub() {
      return this.$store.getters.currentClub || [];
    },
    currentClubPost() {
      return this.$store.getters.currentClubPost || 0;
    },
    currentComments() {
      return this.$store.getters.currentComments;
    },
    currentUserSuggestions() {
      return this.$store.getters.currentUserSuggestions;
    },
    currentHashtagSuggestions() {
      return this.$store.getters.currentHashtagSuggestions;
    },
    currentCommentModalStatus() {
      return this.$store.getters.currentCommentModalStatus;
    },
    selectedComment() {
      return this.$store.getters.currentSelectedComment;
    },
    formattedCaption() {
      const regex = /((?:https?|ftp):\/\/\S+)/g;
      const captionWithLinks = this.currentClubPost.description.replace(
        regex,
        '<a href="$1" target="_blank" style="color:blue;">$1</a>'
      );
      return captionWithLinks;
    },
  },
  async mounted() {
    try {
      await this.getComment();
      await this.getData();
    } catch (error) {
      this.isLoading = false;
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handleLogin() {
      window.location.href = this.urlGethired + "/signin";
    },
    editPost() {
      this.$router.push(this.currentUrl + "/edit").then(() => {
        setTimeout(() => {
          window.location.reload(true);
        }, 0);
      });
    },
    async getData() {
      try {
        await this.$store.dispatch(GET_CLUB_DETAIL, {
          slug: this.$route.params.slug,
        });
        if (
          this.currentClub.is_private === true &&
          this.currentClub.is_joined === 0
        ) {
          this.isLoading = false;
          Swal.fire({
            title: "Oops!",
            text: "You don't have access to this page!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        } else {
          await this.$store.dispatch(GET_CLUB_POST_DETAIL, {
            id: this.$route.params.slug,
            postId: this.$route.params.id,
          });
          this.params = {
            title: this.currentClubPost.title,
            description: this.currentClubPost.description,
          };
          this.photos = this.currentClubPost.photos;
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
          Swal.fire({
            title: "Oops!",
            text: "You don't have access to this page!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        }
      }
    },
    deleteData(id, title) {
      if (this.currentUser) {
        Swal.fire({
          title: "Are you sure?",
          text: `You won't to delete ${title}!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const params = { id: id };
            try {
              Swal.fire({
                title: "Deleted!",
                text: "Your post has been deleted.",
                icon: "success",
              }).then(() => {
                this.$router.push("/clubs/" + "test");
              });
              await this.$store.dispatch(DELETE_CLUB_POST, params);
            } catch (err) {
              console.log(err);
            }
          }
        });
      }
    },
    setIsCommentOpen(value) {
      if (this.selectedComment.id === 0) {
        this.isCommentOpen = value;
      } else {
        this.$store.commit(RESET_SELECTED_COMMENT);
        this.isCommentOpen = true;
      }
    },
    formatDate(date) {
      return date
        ? moment(date)
            .tz(this.defaultTimezone)
            .format("DD MMMM YYYY [at] h:mm A")
        : "";
    },
    async postResultLike(id) {
      if (this.currentUser) {
        try {
          await this.$store.dispatch(POST_LIKE, {
            type: "post",
            id: id,
          });
        } catch (err) {
          if (err.response.data.message === "Already like this content") {
            await this.$store.dispatch(REMOVE_LIKE, {
              type: "post",
              id: id,
            });
          }
        } finally {
          this.getData();
        }
      } else {
        this.handleLogin();
      }
    },
    async getComment() {
      try {
        this.isLoadingComment = true;
        this.result_id = this.$route.params.id;
        await this.$store.dispatch(GET_COMMENTS, {
          id: this.$route.params.id,
        });
        await this.$store.dispatch(GET_USER_SUGGESTIONS, {
          id: this.$route.params.id,
        });
      } finally {
        this.isLoadingComment = false;
      }
    },
    directLinkChallenge(slug) {
      this.$router.push("/challenge/" + slug).then(() => {
        setTimeout(() => {
          window.location.reload(true);
        }, 0);
      });
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    parseUpdatedAt(updatedAt) {
      return new Date(updatedAt.replace(/ /g, "T"));
    },
    formatTimeAgo(updatedAt) {
      const now = new Date();
      const updatedAtDate = this.parseUpdatedAt(updatedAt);
      const timeDifference = now - updatedAtDate;

      if (timeDifference < 1000) {
        return "Just now";
      } else if (timeDifference < 60000) {
        const seconds = Math.floor(timeDifference / 1000);
        return `${seconds} seconds ago`;
      } else if (timeDifference < 3600000) {
        const minutes = Math.floor(timeDifference / 60000);
        return `${minutes} minutes ago`;
      } else if (timeDifference < 86400000) {
        const hours = Math.floor(timeDifference / 3600000);
        return `${hours} hours ago`;
      } else if (timeDifference < 2592000000) {
        const days = Math.floor(timeDifference / 86400000);
        return `${days} days ago`;
      } else if (timeDifference < 31536000000) {
        const months = Math.floor(timeDifference / 2592000000);
        return `${months} mounth ago`;
      } else {
        const years = Math.floor(timeDifference / 31536000000);
        return `${years} years ago`;
      }
    },
    formatDuration(duration) {
      const date = new Date(duration);
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      return `${minutes}.${seconds}`;
    },
    setCurrentPage(page) {
      this.currentPage = page;
      this.getData();
    },

    nextPhoto(postId, total) {
      // Pastikan ada entri untuk postId dalam activeImages
      if (!this.activeImages[postId]) {
        this.$set(this.activeImages, postId, 0);
      }
      // Tambahkan kondisi untuk memastikan bahwa indeks gambar aktif tidak melebihi panjang array v.photos
      if (this.activeImages[postId] < total - 1) {
        this.activeImages[postId]++;
      } else {
        // Jika sudah mencapai gambar terakhir, atur kembali ke gambar pertama
        this.activeImages[postId] = 0;
      }
    },
    prevPhoto(postId, total) {
      // Pastikan ada entri untuk postId dalam activeImages
      if (!this.activeImages[postId]) {
        this.$set(this.activeImages, postId, 0);
      }
      // Tambahkan kondisi untuk memastikan bahwa indeks gambar aktif tidak kurang dari 0
      if (this.activeImages[postId] > 0) {
        this.activeImages[postId]--;
      } else {
        // Jika sudah mencapai gambar pertama, atur kembali ke gambar terakhir
        this.activeImages[postId] = total - 1;
      }
    },
    setActiveImage(postId, index) {
      this.$set(this.activeImages, postId, index);
    },
    updateData() {
      this.getData();
    },
    toggleDropdown(value) {
      this.dropdownStatus = value;
    },
    closeDropdown() {
      this.dropdownStatus = false;
    },
  },
};
</script>
